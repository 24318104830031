import {
  inject,
  Injector,
  LOCALE_ID,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { capitalize } from 'lodash-es';

@Pipe({
  name: 'customTranslate',
  pure: false,
  standalone: true,
})
export class CustomTranslatePipe implements PipeTransform {
  private injector = inject(Injector);

  private locale = this.injector.get(LOCALE_ID);

  public transform(value: any, fieldName: string): string {
    const langKey = capitalize(this.locale);

    return (
      value?.[fieldName + langKey] || (typeof value === 'string' ? value : '')
    );
  }
}
